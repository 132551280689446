<template>
    <div class="white">
        <v-footer class="px-0 pt-0 pb-2 white-background">
            <div class="footer-container">
                <!-- MyMed Logo, Description & Contact Info -->
                <div class="about-segment">
                    <div>
                        <img class="brand-logo" src="https://s3iconimages.mymedicine.com.mm/MyMedicine_Logo.svg">
                    </div>

                    <div id="about-mymed">
                        <p class="ma-0 py-4 body-small-font font-weigt-regular light-grey-color-text" align="left">{{ $t("Customer.MainFooterComponent.MyMedicine_is_a_dedicated_and_ambitious_team_driving_how_to_make_it_easier_for_patients_to_be_their_healthiest_by_making_healthcare_more_accessible_affordable_and_convenient")}}</p>
                    </div>

                    <div class="contact-container">
                        <div class="pa-2 ma-1 rounded-lg light-grey-border">
                            <div class="pb-2 contact-header">
                                <img class="contact-icon" src="https://s3iconimages.mymedicine.com.mm/callCommonHomeIcon.svg">

                                <div>
                                    <span class="body-small-font font-weigt-regular light-grey-color-text">{{ $t("Customer.MainFooterComponent.Call_Us_During")}}</span>
                                </div>
                            </div>

                            <p class="ma-0 body-text font-weigt-regular dark-grey-color-text" align="left">95-9980635588</p>
                        </div>

                        <div class="pa-2 ma-1 rounded-lg light-grey-border">
                            <div class="pb-2 contact-header">
                                <img class="contact-icon" src="https://s3iconimages.mymedicine.com.mm/emailCommonHomeIcon.svg">

                                <span class="body-small-font font-weigt-regular light-grey-color-text">{{ $t("Customer.MainFooterComponent.Contact_Us")}}</span>
                            </div>

                            <p id="email-id" class="ma-0 body-text font-weigt-regular dark-grey-color-text" align="left">{{ $t("Customer.MainFooterComponent.mailid")}}</p>
                        </div>
                    </div>
                </div>

                <!-- Services Segment -->
                <div class="services-segment">
                    <!-- Column 1 -->
                    <div class="service-component">
                        <p class="ma-0 pb-2 font-weight-semi-bold dark-grey-color-text" align="left">{{ $t("Customer.MainFooterComponent.Our_Services")}}</p>

                        <div v-for="item in OurServicesList" :key="item.title" :value="item" class="sub-services-component">
                            <a :href="item.link == '/customer/ePharmacyHome' ? 'https://mymedicine.com.mm/shop' : item.link" :target="item.newTab ? '_blank' : '_self'" class="body-small-font font-weight-regular light-grey-color-text">{{ item.title }}</a>
                        </div>
                    </div>

                    <!-- Column 2 -->
                    <div class="service-component">
                        <p class="ma-0 font-weight-semi-bold dark-grey-color-text" align="left">{{ $t("Customer.MainFooterComponent.Feature_Categories")}}</p>

                        <div v-for="item in FeatureCategoriesList" :key="item.title" :value="item" class="sub-services-component">
                            <a :href="item.link" :target="item.newTab ? '_blank' : '_self'" class="body-small-font font-weight-regular light-grey-color-text">{{ item.title }}</a>
                        </div>
                    </div>

                    <!-- Column 3 -->
                    <div class="service-component">
                        <p class="ma-0 font-weight-semi-bold dark-grey-color-text" align="left">{{ $t("Customer.MainFooterComponent.Company")}}</p>

                        <div v-for="item in CompanyList" :key="item.title" :value="item" class="sub-services-component">
                            <a :href="item.link" :target="item.newTab ? '_blank' : '_self'" class="body-small-font font-weight-regular light-grey-color-text">{{ item.title }}</a>
                        </div>
                    </div>

                    <!-- Column 4 -->
                    <div class="service-component">
                        <p class="ma-0 font-weight-semi-bold dark-grey-color-text" align="left">{{ $t("Customer.MainFooterComponent.Need_Help_Categories")}}</p>

                        <div v-for="item in NeedHelpCategoriesList" :key="item.title" :value="item" class="sub-services-component">
                            <a :href="item.link" :target="item.newTab ? '_blank' : '_self'" class="body-small-font font-weight-regular light-grey-color-text">{{ item.title }}</a>
                        </div>
                    </div>

                    <!-- Column 5 -->
                    <div class="service-component">
                        <p class="ma-0 font-weight-semi-bold dark-grey-color-text" align="left">{{ $t("Customer.MainFooterComponent.Policy_Info")}}</p>

                        <div v-for="item in PolicyInfoList" :key="item.title" :value="item" class="sub-services-component">
                            <a :href="item.link" :target="item.newTab ? '_blank' : '_self'" class="body-small-font font-weight-regular light-grey-color-text">{{ item.title }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </v-footer>

        <v-divider></v-divider>
        <!-- Terms Segment -->
        <div class="pt-2 d-flex justify-space-between align-center terms-segment">
            <div class="d-flex justify-flex-start align-center">
                <img class="terms-icon" src="https://s3iconimages.mymedicine.com.mm/terms_of_use_light_blue_icon.svg" alt="Become a seller">
                <a href="" class="pl-2 body-small-font font-weight-medium dark-grey-color-text">{{ $t("Customer.MainFooterComponent.Terms_of_use")}}</a>
            </div>

            <div id="reserved-rights-text">
                <p class="ma-0 body-small-font font-weight-medium dark-grey-color-text" align="left">{{ $t("Customer.MainFooterComponent.All_Rights_Reserved_By")}} <span class="font-weight-semi-bold light-blue-color-text">My Medicine</span> | 2023</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            OurServicesList: [{
                title: 'Buy Medicine',
                link: '/customer/ePharmacyHome',
                newTab: false
            }, {
                title: 'Doctor Appointments',
                link: '/customer/viewDoctors',
                newTab: true
            }, {
                title: 'Video Consultations',
                link: '/customer/viewDoctors',
                newTab: true
            }],
            FeatureCategoriesList: [{
                title: 'Personal Care',
                link: '',
                newTab: false
            }, {
                title: 'Sexual Wellness',
                link: '',
                newTab: false
            }, {
                title: 'Skin Care',
                link: '',
                newTab: false
            }, {
                title: 'Diabetic Care',
                link: '',
                newTab: false
            }, {
                title: 'Mother and Baby Care',
                link: '',
                newTab: false
            }, {
                title: 'Fitness Supplements',
                link: '',
                newTab: false
            }, {
                title: 'Healthcare Devices',
                link: '',
                newTab: false
            }, {
                title: 'General Health',
                link: '',
                newTab: false
            }],
            CompanyList: [{
                title: 'About Us',
                link: '',
                newTab: false
            }, {
                title: 'Blog',
                link: '',
                newTab: false
            }, {
                title: 'Care Program',
                link: '',
                newTab: false
            }, {
                title: 'Sell at MyMedicine',
                link: '',
                newTab: false
            }],
            NeedHelpCategoriesList: [{
                title: 'Browse All Medicines',
                link: '/customer/allcategories',
                newTab: false
            }, {
                title: 'Browse All Specialties',
                link: '/customer/specializations',
                newTab: true
            }, {
                title: 'Talk to Our Pharmacists',
                link: '',
                newTab: false
            }, {
                title: 'Contact Us',
                link: '',
                newTab: false
            }, {
                title: 'FAQs',
                link: '',
                newTab: false
            }],
            PolicyInfoList: [{
                title: 'Privacy Policy',
                link: '',
                newTab: false
            }, {
                title: 'Terms and Conditions',
                link: '/customer/epharmacytermsandconditions',
                newTab: true
            }, {
                title: 'Shipping and Delivery',
                link: '',
                newTab: false
            }, {
                title: 'Customer Support Policy',
                link: '',
                newTab: false
            }, {
                title: 'Return policy',
                link: '',
                newTab: false
            }]
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../scss/classes.scss';
.footer-container {
    min-height: 350px;
    width: 100vw;
    display: flex;

    @media (max-width: 600px) {
		flex-direction: column;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		flex-direction: row;
        gap: 28px;
	}

	@media (min-width: 1265px) {
		flex-direction: row;
        gap: 32px;
	}
}
.about-segment {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;

    @media (max-width: 600px) {
        width: 100vw;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		width: 45vw;
	}

	@media (min-width: 1265px) {
		width: 50vw;
	}
}

.contact-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    
    @media (max-width: 600px) {

    }

	@media (min-width: 601px) and (max-width: 900px) {
        width: 90%;
	}

    @media (min-width: 901px) and (max-width: 1264px) {
        width: 80%;
	}

	@media (min-width: 1265px) {
        width: 80%;
	}
}
.services-segment {
    width: 100%;
    display: flex;

    @media (max-width: 600px) {
        flex-direction: column;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		flex-direction: row;
        justify-content: space-evenly;
        gap: 8px;
	}

	@media (min-width: 1265px) {
		flex-direction: row;
        justify-content: space-evenly;
	}
}
.service-component {
    
    @media (max-width: 600px) {
        min-width: 100%;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		min-width: 10%;

        p {
            height: 54px;
            font-size: 14px;
        }
	}

	@media (min-width: 1265px) {
		min-width: 15%;

        p {
            font-size: 16px;
        }
	}
}
// .terms-container {
//     display: flex;
//     flex-direction: row;
//     align-items: center;

//     @media (max-width: 600px) {
//         justify-content: space-evenly;
//     }

//     @media (min-width: 601px) and (max-width: 1264px) {
//         gap: 16px;
//     }

//     @media (min-width: 1265px) {
//         gap: 16px;
//     }
// }
.terms-component {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    @media (max-width: 600px) {
        justify-content: space-evenly;
    }

    @media (min-width: 601px) and (max-width: 1264px) {
        gap: 8px;
    }

    @media (min-width: 1265px) {
        gap: 8px;
    }
}
.sub-services-component {
    text-align: left;
}
.contact-icon {
    @media (max-width: 600px) {
        width: 12px;
        height: 12px;
    }

    @media (min-width: 601px) and (max-width: 1264px) {
        size: 12px;
        width: 12px;
        height: 12px;
    }

    @media (min-width: 1265px) {
        size: 14px;
        width: 14px;
        height: 14px;
    }
}
.terms-icon {
    width: 20px;
    height: 20px;
}

#about-mymed {
    @media (max-width: 600px) {
        width: 95%;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		width: 100%;
	}

	@media (min-width: 1265px) {
		width: 100%;
	}
}
.contact-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}
.footer-container a, .terms-segment a {
    text-decoration: none;
}
#reserved-rights-text {
    @media (max-width: 600px) {
        margin: auto;
    }

    @media (min-width: 601px) and (max-width: 1264px) {

    }

    @media (min-width: 1265px) {
        
    }
}
</style>